<template>
  <div>
    <vue-element-loading
    :active="appLoading"
    spinner="bar-fade-scale"
    color="#4480C5"
    size="60"
    is-full-screen
  />
    <v-snackbar v-model="showSnackBar" color="primary" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
  
  
    <v-layout wrap justify-center>
      <v-flex pt-5 xs11 sm11 md11 lg11 xl11 >
  
        <!-- Header Section -->
        <v-layout wrap justify-start class="my-3">
          <v-flex xs12 sm9 md9 lg10 text-start align-center pt-2 class="headingChurch">Church List</v-flex>
          <v-flex xs12 sm3 md3 lg2 text-end align-center ><v-btn class="churchbtn" color="blue" style="color:white"  @click="goToAddChurch">ADD CHURCH</v-btn></v-flex>
        </v-layout>
  
      
        <v-layout wrap justify-start>
          <v-flex xs12>
               <!-- filter Section -->
       <v-layout wrap justify-start pb-3>
                
                  <v-flex xs12 sm6 md2 lg3 pr-lg-2 pr-sm-2 pr-md-2 pr-0 pb-2>
                    <v-select :hide-details="true"
                   :items="riteArray" v-model="rite" item-text="name" item-value="_id"
                    label="Rite"
                    style="font-family:interregular"
                    solo  clearable
                    dense flat outlined
                  ></v-select>
  
                    </v-flex>
  
                    <v-flex xs12 sm6 md2 lg2 pr-lg-2 pr-sm-0 pr-md-2 pr-0 pb-2>
                      <v-select :hide-details="true"
                      v-model="diocese"
                      :items="dioceseArray"
                       item-text="name" item-value="_id"
                      label="Diocese"
                    style="font-family:interregular"
                      solo  clearable
                      dense flat outlined
                    ></v-select>
    
    
                      </v-flex>

                      <v-flex xs12 sm6 md2 lg2  pr-lg-2 pr-sm-2 pr-md-2 pr-0 pb-2>
                        <v-select :hide-details="true"
                        v-model="parishType"
                        :items="parishTypeArray"
                        item-text="name"
              item-value="_id"
                        label="Parish Type"
                        style="font-family:interregular"
                        solo  clearable
                        dense flat outlined
                      ></v-select>
      
                        </v-flex>
  
                    <v-flex xs12 sm6 md2 lg2 pr-lg-2 pr-sm-0 pr-md-2 pr-0 pb-2  style="font-family:interregular">
                      <v-select :hide-details="true"
                   v-model="selectedServicesNames"
                      :items="servicesArray" item-text="name"
              item-value="_id"
                      label="Services"
                    style="font-family:interregular"
                      solo  clearable
                      dense flat outlined
                    ></v-select>
    
    
                      </v-flex>
                      <v-flex xs12 sm6 md4 lg3  pb-2   pr-lg-0 pr-sm-2 pr-md-0 pr-0>
                        <v-text-field
                        :hide-details="true"
                         v-model="keyword"
                          append-icon="mdi-magnify"
                          label="Search"
                          solo  clearable
                          dense flat outlined
                         style="font-family:interregular;font-size:13px;"
                        ></v-text-field>
                        </v-flex>
                    
                  
  
              </v-layout> 
          
              <!-- Table section -->
              <v-layout wrap justify-center>
                <v-flex xs12>
                  <v-data-table
                  :headers="filteredHeaders"
                  :items="churchlist"
                  hide-default-footer
                  :header-props="{sortIcon:null}"
                  class="elevation-1 text--black"
                  id="virtual-scroll-table"
                  @click:row="redirectToViewPage"
                  style="cursor: pointer;"
                >
                  <template v-if="userRole == 'admin'" v-slot:[`item._id`]="{ item }">
                    <v-icon small color="red" class="ml-4" @click.stop="openDeleteDialog(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
                </v-flex>
              </v-layout>
              <!-- pagination -->
              <v-layout wrap justify-center pt-2>
                <v-flex xs12>
                  <div class="text-center pb-5" v-if="pages > 1">
                    <v-pagination :length="pages" v-model="currentPage" color="primary white--text"
                      circle></v-pagination>
                  </div>
                </v-flex>
              </v-layout>
           
          </v-flex>
        </v-layout>
        </v-flex>
        </v-layout>
        <!-- delete dialog -->
        <v-dialog v-model="dialog" max-width="600">
          <v-card class="dialog-card">
            <v-card-title class=" grey lighten-2 d-flex justify-center">
              <v-icon color="red" size="32">mdi-alert</v-icon>
              <span class="ml-2">Confirm Deletion</span>
            </v-card-title>
            <v-card-text class="py-5 text-center text-des">
              <div class="body-1">
                Are you sure you want to delete this Church? 
                <br />
                This action <strong>cannot be undone</strong>.
              </div>
              <v-divider class="my-3"></v-divider>
              <v-row class="pt-2" no-gutters>
                <v-col>
                  <img src="@/assets/images/church_icon.png" width="40px" height="40px" class="dialog-icon" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="d-flex justify-center pa-2">
              <v-btn style="font-family: interBold;font-size:13px;color:white" color="#cf3a45"
              @click="dialog = false">Cancel</v-btn>
              <v-btn style="font-family: interBold;font-size:13px;color:white" color="blue darken-1"
              @click="confirmDelete">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        
      </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    data() {
      return {
        userRole: localStorage.getItem("role"),
        showSnackBar:false,
      timeout:5000,
      msg: "",
      appLoading: false,
        deleteDialog: false, // Controls the dialog visibility
        keyword: "",
        riteArray: [],
        rite:"",
        parishType:"",
        parishTypeArray: [],
        diocese:"",
        dioceseArray: [],
        servicesArray : [
        { name: "Holy Mass" },
        { name: "Confession" },
        { name: "Adoration" },
      ],
      selectedServicesNames: [],
        selectedRite: null,
        selectedLanguage: null,
        selectedLocation: null,
        selectedType: null,
        rites: ['Syro malabar'],
        languages: ['Malayalam'],
        locations: ['Kottayam'],
        types: ['Holymass'],
        churchlist:[],
        dialog: false,
        itemToDelete: null,
        headers: [
        { text: "Name", value: "name", width: "350px" },
        { text: "Rite", value: "rite.name", width: "150px" },
        { text: "Diocese", value: "diocese.name", width: "200px" },
        { text: "Location", value: "address.city", width: "100px" },
        { text: "Country", value: "address.country", width: "50px" },
        { text: "Actions", value: "_id", width: "20px" },
      ],
        page: 1,
        currentPage: 1,
        pages: 0,
        limit: 10,
      };
    },
    watch:{

      currentPage() {
        const userRole = localStorage.getItem("role");
        if (userRole === 'admin') {
      this.getData();
    } else if (userRole === 'subadmin') {
      this.getDataForSubAdmin();
    }
      },
      keyword() {
        this.currentPage=1
        const userRole = localStorage.getItem("role");
        if (userRole === 'admin') {
      this.getData();
    } else if (userRole === 'subadmin') {
      this.getDataForSubAdmin();
    }
         },
 rite() {
  const userRole = localStorage.getItem("role");
        if (userRole === 'admin') {
      this.getData();
    } else if (userRole === 'subadmin') {
      this.getDataForSubAdmin();
    }
      this.currentPage=1

      this.diocese="",
      this.selectedServicesNames="",
      this.parishType=""
     
      },
      diocese() {
        const userRole = localStorage.getItem("role");
        if (userRole === 'admin') {
      this.getData();
    } else if (userRole === 'subadmin') {
      this.getDataForSubAdmin();
    }
      this.currentPage=1
      
      this.selectedServicesNames="",
      this.parishType=""
     
     },

     parishType() {
      const userRole = localStorage.getItem("role");
        if (userRole === 'admin') {
      this.getData();
    } else if (userRole === 'subadmin') {
      this.getDataForSubAdmin();
    }
      this.selectedServicesNames=""
     
     this.currentPage=1
   },
     selectedServicesNames() {
     
      const userRole = localStorage.getItem("role");
        if (userRole === 'admin') {
      this.getData();
    } else if (userRole === 'subadmin') {
      this.getDataForSubAdmin();
    }
      this.currentPage=1
    },
    
    },
    computed: {
    filteredHeaders() {
      if (this.userRole === 'subadmin') {
        return this.headers.filter(header => header.value !== '_id');
      }
      return this.headers;
    }
  },
    mounted() {

      const userRole = localStorage.getItem("role");
      if (userRole === 'admin') {
      this.getData();
    } else if (userRole === 'subadmin') {
      this.getDataForSubAdmin();
    }
       
          this.getRite(); 
          this.getDiocese();
          this.getParishType();
          
      },
    methods: {
      redirectToViewPage(item) {
        this.$router.push('/churchView?id=' + item._id);
      },
      getRite() {
      this.appLoading = true;
      axios({
        url: "/get/rite/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.riteArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getDiocese() {
      this.appLoading = true;
      axios({
        url: "/get/diocese/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.dioceseArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getParishType() {
      this.appLoading = true;
      axios({
        url: "/get/parish/type/list",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.parishTypeArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    
      getData() {
              this.appLoading = true;
           //   const currentTime = new Date().toISOString(); // Get current time in ISO format
              axios({
                  url: "/get/church/list",
  
                  method: "GET",
                  headers: {
                      token: localStorage.getItem("token"),
                  },
                  params: {
                    limit: this.limit,
                    page: this.currentPage,
                    keyword: this.keyword,
                    rite: this.rite,
                    diocese: this.diocese,
                    service: this.selectedServicesNames,
                    parishType: this.parishType,
  
                  },
              })
                  .then((response) => {
                      this.appLoading = false;
                      this.churchlist = response.data.data;
                      // this.pages = response.data.pages;
                      // this.totalData = response.data.count;
                      this.pages = Math.ceil(response.data.totalLength / this.limit);
                  })
                  .catch((err) => {
                      this.appLoading = false;
                      this.ServerError = true;
                      console.log(err);
                  });
          },

          getDataForSubAdmin() {
              this.appLoading = true;
           //   const currentTime = new Date().toISOString(); // Get current time in ISO format
              axios({
                  url: "/subadmin/churches",
  
                  method: "GET",
                  headers: {
                      token: localStorage.getItem("token"),
                  },
                  params: {
                    limit: this.limit,
                    page: this.currentPage,
                    keyword: this.keyword,
                    rite: this.rite,
                    diocese: this.diocese,
                    service: this.selectedServicesNames,
                    parishType: this.parishType,
  
                  },
              })
                  .then((response) => {
                      this.appLoading = false;
                      this.churchlist = response.data.data;
                      // this.pages = response.data.pages;
                      // this.totalData = response.data.count;
                      this.pages = Math.ceil(response.data.totalLength / this.limit);
                  })
                  .catch((err) => {
                      this.appLoading = false;
                      this.ServerError = true;
                      console.log(err);
                  });
          },
  
  
          openDeleteDialog(item) {
        this.itemToDelete = item;
        this.dialog = true;
      },
      confirmDelete() {
        if (this.itemToDelete) {
          this.deleteItem(this.itemToDelete);
        }
        this.dialog = false;
      },
      deleteItem(r) {
        var data = { id: r._id };
        axios({
          url: "/delete/church",
          method: "POST",
          data: data,
          headers: {
            token: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
      },
    
      goToAddChurch() {
        this.$router.push({ name: 'addChurch' });
      },
      editChurch() {
        this.$router.push({ name: 'editChurch' });
      }
    },
    // computed: {
    //   filteredChurches() {
    //     return this.churches.filter((church) => {
    //       const matchesSearch =
    //         this.search === '' ||
    //         church.name.toLowerCase().includes(this.search.toLowerCase());
    //       const matchesRite =
    //         !this.selectedRite || church.rite === this.selectedRite;
    //       const matchesLanguage =
    //         !this.selectedLanguage || church.language === this.selectedLanguage;
    //       const matchesLocation =
    //         !this.selectedLocation || church.location === this.selectedLocation;
    //       const matchesType =
    //         !this.selectedType || church.type === this.selectedType;
  
    //       return (
    //         matchesSearch &&
    //         matchesRite &&
    //         matchesLanguage &&
    //         matchesLocation &&
    //         matchesType
    //       );
    //     });
    //   },
    // },
  };
  </script>
  
  <style scoped>
     
     
  .dialog-card {
    font-family: interbold;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }
  
  .dialog-icon {
    animation: pulse 1s infinite alternate;
  }
  
  .dialog-button {
    min-width: 120px;
  }
  
  @keyframes pulse {
    from {
      transform: scale(1);
      opacity: 0.7;
    }
    to {
      transform: scale(1.1);
      opacity: 1;
    }
  }
  </style>
  